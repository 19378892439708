export enum CareSlug {
  PRIMARY_CARE = "primary-care",
  ANNUAL_PHYSICAL = "annual-physical",
  WOMENS_HEALTH = "womens-health-concern",
  EAR_ACHE = "ear-ache",
  WELLNESS_VISIT = "wellness-visit",
  LGBTQ_HEALTH = "lgbtq-health-and-wellness",
  EXISTING_CONDITION = "existing-condition",
  CONTRACEPTION = "visit-for-contraception",
  STI = "sti-test",
  SPORTS_PHYSICAL = "sports-physical-exam",
  URGENT_CARE = "urgent-care",
  COLD_AND_FLU = "cold-flu-symptoms",
  INJURY = "injury",
  RASH = "rash",
  URINARY_TRACT_INFECTION = "urinary-tract-infection",
  ABDOMINAL_PAIN = "abdominal-pain",
  COVID_CARE = "covid-care",
  COVID_SICK_VISIT = "covid-sick-visit",
  COVID_EXPOSURE_SCREENING = "covid-exposure-screening",
  COVID_TESTING = "covid-19-testing",
  COVID_TREATMENT = "covid-treatment",
  COVID_VACCINATION = "covid-19-vaccination",
  COVID_TRAVEL_TESTING = "covid-19-travel-testing",
  SAME_DAY_RT_PCR = "same-day-rt-pcr",
  SAME_DAY_ANTIGEN = "same-day-antigen",
  SAME_DAY_NAAT = "same-day-naat",
  NEXT_DAY_RT_PCR = "next-day-rt-pcr",
  WORKPLACE_HEALTH = "workplace-health",
  SORE_THROAT = "sore-throat",
  FAMILY_PLANNING = "family-planning",
}
